<template>
  <div class="main">
    <div style="max-width:1560px;margin:0 auto">
      <el-carousel height="630px">
        <el-carousel-item v-for="item in 4" :key="item">
          <img src="http://www.szxcd.com.cn/content/img/banner.jpeg" alt="" class="carouselItem">
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="container">
      <p style="margin-top:10px">
        <router-link class="position" :to="{name:'index',params:{index:0}}">首页 &gt;&gt;</router-link>
        <router-link class="position" :to="{name:'news',params:{index:5}}"> 客户案例 <span v-if="currentname">&gt;&gt;</span></router-link>
        <span class="position"> {{currentname}}</span>
      </p>
      <div class="productscontainer">
        <div class="productscontainerL">
          <div class="productscontainerL1">客户案例</div>
          <div tag="div" @click="currenttype=item.type;currentname=item.name" class="productscontainerL2" v-for="(item,index) in typelist" :key="index">{{item.name}}</div>
        </div>
        <div class="productscontainerR">
          <p class="title">{{currentname}}</p>
          <p class="info">6月13日太仓长臂猿机器人科技有限公司迎来了自持续改善项目启动以来的首次KAIZEN成果汇报与积分兑换会。在会期间，每位员工都积极展示了个人的提案成果并分享改善成果心得。</p>
          <img class="newsimg" src="http://www.szxcd.com.cn/content/img/aboutbanner.jpg" alt="">
          <p class="info">6月13日太仓长臂猿机器人科技有限公司迎来了自持续改善项目启动以来的首次KAIZEN成果汇报与积分兑换会。在会期间，每位员工都积极展示了个人的提案成果并分享改善成果心得。</p>
          <img class="newsimg" src="http://www.szxcd.com.cn/content/img/aboutbanner.jpg" alt="">
          <p class="info">6月13日太仓长臂猿机器人科技有限公司迎来了自持续改善项目启动以来的首次KAIZEN成果汇报与积分兑换会。在会期间，每位员工都积极展示了个人的提案成果并分享改善成果心得。</p>
          <img class="newsimg" src="http://www.szxcd.com.cn/content/img/aboutbanner.jpg" alt="">
          <p class="info">6月13日太仓长臂猿机器人科技有限公司迎来了自持续改善项目启动以来的首次KAIZEN成果汇报与积分兑换会。在会期间，每位员工都积极展示了个人的提案成果并分享改善成果心得。</p>
          <img class="newsimg" src="http://www.szxcd.com.cn/content/img/aboutbanner.jpg" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'caseinfo',
  components: {
  },
  data(){
    return{
      currentname:'',
      parentname:'',
      currenttype:0,
      typelist:[
        {
          type:1,
          name:'客户案例'
        },
        
      ],
      list:[
        
      ]
    }
  },
  created () {
    window.scrollTo(0,0)
  },
  watch: {
      //监听路由地址的改变
      '$route.query':{
        handler(newval){
          this.currentname=newval.name
          this.parentname=newval.parentname
        },
        immediate:true
      }
  },
}
</script>

<style scoped>
.info{
  line-height: 1.75;
    font-size: 16px;
    margin: 0.5em 0;
}
.newsimg{
  display: block;
  margin: 0 auto;
  max-width: 100%;
}
.title{
  font-size: 26px;
  padding: 10px 0;
  text-align: center;
  display: block;
  border-bottom: 1px dashed #ccc;
  margin-bottom: 20px;
}
::v-deep .el-pagination .btn-next{
  padding: 0 10px;
}
::v-deep .el-pagination .btn-prev{
  padding: 0 10px;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: #fad749;
}
.pageclass{
  width: 910px;
  margin-left: 290px;
  text-align: center;
  margin-top: 60px;
  margin-bottom: 60px;
}
.productscontainerR .productitem:nth-child(4n){
  margin-right: 0;
}
.productitem img{
  width: 210px;
  height: 210px;
  object-fit: cover;
}
.productitem p{
  line-height: 43px;
  font-size: 16px;
  color: #444;
  text-align: center;
}
.productitem:hover p{
  color:#fad749;
  transition: all .5s;
}
.productitem{
  font-size: 0;
  width: 210px;
  height: 253px;
  border: 1px solid #ccc; 
  margin-right: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  transition: all .5s;
}
.productscontainerL2{
  cursor: pointer;
  line-height: 50px;
  text-align: center;
  color: #fff;
  font-size: 18px;
  background-color: #70b9ec;
  margin-bottom: 1px;
}
.productscontainerL1{
  line-height: 80px;
  font-size: 30px;
  background-color: #000;
  color: #fff;
  text-align: center;
  margin-bottom: 20px;
}
.productscontainerL{
  width: 270px;
}
.productscontainerR{
  width: 910px;
  /* border: 1px solid #ccc; */
  margin-bottom: 10px;
}
.productscontainer{
  display: flex;
  margin-top: 34px;
  justify-content: space-between;
  flex-wrap: wrap;
}
a{
  text-decoration: none;
  font-size: 14px;
  color: #666;
}
.carouselItem{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.active{
  background-color: #ffd800;
}
.position{
  font-size: 14px;
  color: #666;
}
</style>
